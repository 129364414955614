import { graphClient } from "..";

const getUserDetails = async () => {
  try {
    const response = await graphClient
      .api("/me")
      .get();
    return response;
  } catch (error) {
    console.error("Error getting root folder item ID:", error);
    throw error;
  }
};
export { getUserDetails };


const getSharePointFolders = async () => {
  const id = "3b0c2db8-7045-4fd6-b920-8d652173d6c7";
  const listid = "b6e754ae-6e70-4410-a0f9-c1a03261dd2f";
  // /sites/{site-id}/lists/{list-id}/items
  try {
    const response = await graphClient
      .api(`/sites/${id}/lists/${listid}/items`)
      .get();
    const folders = response?.value?.filter(
      (item) => item.contentType.name === "Folder" && item.id != "1"
    );
    const UIData = folders.map((item) => {
      const parts = item.webUrl.split("/");
      return {
        name: decodeURIComponent(parts[parts.length - 1]),
        webUrl: item.webUrl,
        id:item.eTag
      };
    });
    return UIData;
  } catch (error) {
    console.error("Error fetching SharePoint folders:", error);
  }
};
export {  getSharePointFolders };
