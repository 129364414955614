import React from "react";
import { FcFolder } from "react-icons/fc";
export default function FolderItem({ weburl, name, id, changeHandler,selectedfolder }) {
  return (
    
      <div className="form-check col-3 me-3 mb-4 folder-wrapper">
        <input
          className="form-check-input"
          type="radio"
          onChange={(e) => changeHandler(e)}
          checked={name === selectedfolder}
          name="folders"
          value={name}
          id={id}
        />
        <div className="form-check  folder-selection-wrapper">
          <div>
            <label className="form-check-label" htmlFor={id}>
              <div className="d-flex gap-2 align-items-center">
                <span>
                  <FcFolder fontSize={18} />
                </span>
                <span>{name}</span>
              </div>
            </label>
          </div>
        </div>
      </div>

    
  );
}
