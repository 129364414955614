import React, { useState } from "react";

export default function TopicModal({ topic, updateName, deleteTopic }) {
  const [newtopicName, setnewtopicName] = useState(topic.topic_desc);
  return (
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      className="modal fade"
      id={`chattopicmodal${topic.topic_id}`}
      tabIndex="-1"
      aria-labelledby={`chattopicmodal${topic.topic_id}`}
      aria-hidden="true"
    >
      <div className="modal-dialog position-relative">

        <div className="modal-content px-4 py-2 rounded-0">
          <div className="position-absolute close-btn">
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-header d-block border-0 pb-0">
            <h5 className="modal-title" id="chatTitleModalLabel">
              {topic.topic_desc.replace(/"/g, '')}
            </h5>
            <p>
              <strong>Note:</strong> Delete title will delete your chat also
              form the app
            </p>
          </div>
          <div className="modal-body">
            <div className="form-floating mb-3">
              <input
                type="text"
                className="form-control"
                id="floatingInput"
                placeholder="Chat title"
                value={newtopicName.replace(/"/g, '')}
                onChange={(e) => setnewtopicName(e.target.value)}
              />
              <label htmlFor="floatingInput">Chat Title</label>
            </div>
          </div>
          <div className="modal-footer border-0">
            <button
              type="button"
              onClick={() => {
                updateName(topic.topic_id, newtopicName);
                // setnewtopicName("");
              }}
              className="btn btn-outline-primary px-5"
              data-bs-dismiss="modal"
            >
              Save
            </button>
            <button
              onClick={() => {
                deleteTopic(topic.topic_id);
              }}
              type="button"
              className="btn border-0 pe-0 text-danger"
              data-bs-dismiss="modal"
            >
              Delete
            </button>
            <button
              type="button"
              className="btn border-0 pe-0"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
