import { useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { useIsAuthenticated } from "@azure/msal-react";
import Chat from "../../Chat/index";
import carpenterLogo from "../../carpenter-technology-logo.svg"
import { getUserDetails } from "../../Chat/Sharepoint";
import React, { useState } from "react";
import axios from "axios";


const url = "https://persistentaiwebapp.azurewebsites.net/";

const Login = () => {


  const [userId, setUserId] = useState("")

  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  const handleSignIn = () => {
    try {
      instance.loginRedirect({ scopes: ["user.read"] });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Check if user is authenticated
    if (isAuthenticated) {
      getUserDetails().then((res) => {
        try {
          axios
            .post(`${url}/login`, {
              user_name: res.userPrincipalName,
              user_ts: new Date().toISOString(),
              user_type: "User",
              user_id: res.id,
            })
        } catch (error) {
          console.log(error);
        }
      })
      // Redirect to Chat component or perform any other actions
    } else {
      console.log("User authentication failed");
    }
  }, [isAuthenticated]);

  return (
    <div >
      {!isAuthenticated && (<div className="loginContainer">
        <div className="login-box login-main-wrapper w-full p-6 bg-white rounded-md shadow-md lg:max-w-xl p-5">
          <div className="company-big-logo-wrapper mb-5">
            <img className='logo' src={carpenterLogo} id="carpenterLogo" />
          </div>
          <div className='mb-5'>
            <h5 className='text-center'>
              <strong>
                GenAI Conversational Interface
              </strong>
            </h5>
          </div>
          <div className="mt-6 d-grid position-relative">
            <button
              onClick={handleSignIn}
              className="btn ms-btn btn-outline-primary"
            >
              <span>
                <svg className='ms-icon position-absolute' viewBox="0 0 32 32" width="24"
                  height="24" fill="none" xmlns="http://www.w3.org/2000/svg"><g id="SVGRepo_bgCarrier" strokeWidth="0"></g><g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g><g id="SVGRepo_iconCarrier"> <rect x="17" y="17" width="10" height="10" fill="#FEBA08"></rect> <rect x="5" y="17" width="10" height="10" fill="#05A6F0"></rect> <rect x="17" y="5" width="10" height="10" fill="#80BC06"></rect> <rect x="5" y="5" width="10" height="10" fill="#F25325"></rect> </g></svg>
              </span>
              <span>
                Login With Microsoft
              </span>
            </button>
          </div>
        </div>
      </div>)}

      {isAuthenticated && <Chat />}
    </div>
  );
};

export default Login;