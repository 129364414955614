import axios, { CancelToken, isCancel } from "axios";
import React, { useState, useRef } from "react";
import { useEffect } from "react";
import { BiCopy } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { BsChatLeftText } from "react-icons/bs";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { useMsal } from "@azure/msal-react";
import HashLoader from "react-spinners/HashLoader";
import "bootstrap/dist/css/bootstrap.css";
import { Tabs, Tab, PageItem } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import Accordion from "react-bootstrap/Accordion";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import SharePointModal from "./SharePointModal";
import { getUserDetails } from "./Sharepoint";
import TopicModal from "./TopicModal";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Offcanvas from 'react-bootstrap/Offcanvas';


const examples = [
  "Tell me something about you",
  "How to use Tailwind CSS with React",
  "How to use write a java code",
  "What is Gatsby ",
];


// const empid = window.localStorage.getItem('user_id');
let empid;
const url = "https://persistentaiwebapp.azurewebsites.net";
// var model=0

const Chat = () => {
  const [chat, setChat] = useState([]);
  const [chatHistory, setChatHistory] = useState([]);
  const [input, setInput] = useState([]);
  const [selected, setSelected] = useState("");
  const [loader, setLoader] = useState(false);

  const [prompt_list, setprompt_list] = useState([]);
  const [files, setfiles] = useState([null]);
  const [checkedItems, setCheckedItems] = useState([]);

  const [file_upload_option, set_fileUpload] = useState("PDFFILE");
  const [pdf_FIle_Query, setPdf_FIle_Query] = useState("");
  const [pdf_Link_Query, setPdf_Link_Query] = useState("");
  const [selectedFolder, setselectedFolder] = useState("");
  const [adminPrompts, setAdminPrompts] = useState([]);
  const [selectAdminPrompt, setSelectAdminPrompt] = useState([]);
  const [clickAdminPrompt, setClickAdminPrompt] = useState("");
  const [userDetails, setuserDetails] = useState("");
  const [llmModel, setllmModel] = useState(1);
  const [pdfLink, setpdfLink] = useState(null);
  const [category, setCategory] = useState("");
  const ref = useRef();
  const Inputref = useRef();
  const closeRef = useRef();
  const messageEl = useRef(null);
  const [textPrompt, setTextPrompt] = useState('');
  const [PdfUploadInputValue, setPdfUploadInputValue] = useState('');
  const [fileExtensionChecker, setFileExtensionChecker] = useState('');
  const [pdfUrlInputValue, setPdfUrlInputValue] = useState('');
  const [pdfFile_Loader, setPdfFile_Loader] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const cancelFileUpload = useRef(null);

  useEffect(() => {
    let combinedPrompt = '';
    if (input.length) {
      const uniqueList = Array.from(new Set(input));
      for (let i = 0; i < uniqueList.length; i++) {
        combinedPrompt = combinedPrompt + uniqueList[i].prompt_desc + '\n'
      }
      setTextPrompt(combinedPrompt)
    } else {
      setTextPrompt("")
    }

  }, [input])

  useEffect(() => {
    if (messageEl && chat.length) {
      messageEl.current.addEventListener('DOMNodeInserted', event => {
        const { currentTarget: target } = event;
        target.scroll({ top: target.scrollHeight, behavior: 'smooth' });
      });
    }
  }, [chat])

  useEffect(() => {
    onSelectUserPrompt();
    getUserDetails().then((res) => {
      setuserDetails(res);
    });
  }, [checkedItems]);

  useEffect(() => {
    setPdfUploadInputValue(false);
    setpdfLink('');
    setPdfUrlInputValue(false);
    setFileExtensionChecker('');
    setPdf_FIle_Query('');
    setPdf_Link_Query('');

    if (file_upload_option === "PDFLINK" && PdfUploadInputValue) {
      cancelUpload();
    }

  }, [file_upload_option]);



  const { instance } = useMsal();
  const handleSignOut = () => {
    instance.logoutRedirect();
  };

  const addconversation = () => {
    // setselectedFolder([]);
    set_fileUpload("PDFFILE");
    setselectedFolder("");
    setCategory("general");
    setChat([]);
    setPdfUploadInputValue('');
    setPdf_FIle_Query('');
    setPdf_Link_Query('');
    setpdfLink('');
    if (Inputref.current) {
      reset();
    }
    const dt = new Date().getTime();
    const ch = chatHistory;
    setSelected(dt);
  };



  const selectconversation = async (topic_id) => {
    // setPopup(false);
    setSelected((prevSelected) => topic_id);
    setChat([]);
    let title1 = [{}];

    const createTitle1 = await axios
      .get(`${url}/topic/${topic_id}/conversation`, {})
      .then((createTitle1) => {

        title1 = createTitle1.data;
        title1.map((item, index) => {
          let newChat;
          try {
            newChat = [
              { role: "user", content: `${item.category != "general" ? item.category : ""}\n${item.request}` },
              { role: "assistant", content: JSON.parse(item.response) },
            ];
          } catch (error) {
            if (item.category != "general") {
              newChat = [
                { role: "user", content: `${item.category}\n${item.request}` },
                { role: "assistant", content: item.response },
              ];
            } else {
              newChat = [
                { role: "user", content: `${item.request}` },
                { role: "assistant", content: item.response },
              ];
            }

          }
          setChat((prevChat) => [...prevChat, ...newChat]);
        });
      });

  };

  useEffect(() => {
    getconverationdata();
    adminPromptList();
  }, []);

  useEffect(() => {
    if (llmModel == 0 && textPrompt.length > 65040) {
      setTextPrompt((prevtext) => prevtext.substring(0, 65040))
    } else if (llmModel == 1 && textPrompt.length > 130644) {
      setTextPrompt((prevtext) => prevtext.substring(0, 130644))

    } else if (llmModel == 2 && textPrompt.length > 511500) {
      setTextPrompt((prevtext) => prevtext.substring(0, 511500))

    } else {
      setTextPrompt((prevtext) => prevtext)
    }
    console.log("llm value", llmModel);
  }, [llmModel])


  const handleSend = async () => {
    let categoryval;
    let isPdfExist;

    if (chat.length) {
      isPdfExist = chat.find((item) => {

        return item.role == "user" && item.content.includes(category)
      });
    }

    // if (selectedFolder?.length) {
    //   categoryval = `Sharepoint Folder: ${selectedFolder}`
    // } else if (category?.startsWith('http://') || category?.startsWith('https://')) {
    //   categoryval = `PDF Link: ${category}`
    // } else if (category?.toLowerCase()?.endsWith('.pdf')) {
    //   categoryval = `PDF File: ${category}`
    // } else {
    //   categoryval = category
    // }

    const id = new Date().getTime();
    if (!selected) {
      const id = new Date().getTime();
      setSelected((prevSelected) => id);
    }
    if (textPrompt.trim()) {
      if (category && category != 'general') {
        if (category.startsWith('http://') || category.startsWith('https://')) {
          setChat([...chat, { role: "user", content: !isPdfExist ? `PDF Link: ${category}\n${textPrompt.trim(/^\s*[\r\n]/gm, "")}` : textPrompt.trim(/^\s*[\r\n]/gm, "") }]);
        } else {
          setChat([...chat, { role: "user", content: !isPdfExist ? `PDF File: ${category}\n${textPrompt.trim(/^\s*[\r\n]/gm, "")}` : textPrompt.trim(/^\s*[\r\n]/gm, "") }]);
        }

      } else {
        setChat([...chat, { role: "user", content: `${textPrompt.trim(/^\s*[\r\n]/gm, "")}` }]);
      }

      setInput([]);
      setTextPrompt("");
      let apires = "";
      setLoader(true);
      try {
        apires =
          await axios
            .post(`${url}/response`, {
              category: category,
              topic_id: selected,
              user_id: userDetails?.id,
              query: textPrompt,
              prompt_ids: checkedItems,
              admin_prompt_ids: selectAdminPrompt.map((i) => i.prompt_id),
              llm_id: llmModel, // pass the response value as the prompt parameter
            })
        setTextPrompt('');
        setLoader(false);
        setCheckedItems([]);
        // setPopup(true);
        if (category && category != 'general') {
          if (category.startsWith('http://') || category.startsWith('https://')) {
            // setChat([
            //   ...chat,
            //   { role: "user", content: `PDF Link: ${category}\n${textPrompt.trim(/^\s*[\r\n]/gm, "")}` },
            //   { role: "assistant", content: apires.data.response },
            // ]);

            //hide first time URL Link if response
            if (!chat[chat.length - 1]?.content?.includes(textPrompt.trim(/^\s*[\r\n]/gm, "")) && chat[chat.length - 1]?.content?.includes(category)) {

              setChat([...chat, { role: "user", content: textPrompt.trim(/^\s*[\r\n]/gm, "") },
              { role: "assistant", content: apires.data.response }
              ]);

            } else {
              setChat([
                ...chat,
                { role: "user", content: `${category}\n${textPrompt.trim(/^\s*[\r\n]/gm, "")}` },
                { role: "assistant", content: apires.data.response },
              ]);
            }



          } else {
            if (!chat[chat.length - 1]?.content?.includes(textPrompt.trim(/^\s*[\r\n]/gm, "")) && chat[chat.length - 1]?.content?.includes(category)) {

              setChat([...chat, { role: "user", content: textPrompt.trim(/^\s*[\r\n]/gm, "") },
              { role: "assistant", content: apires.data.response }
              ]);

            } else {
              setChat([
                ...chat,
                { role: "user", content: `${category}\n${textPrompt.trim(/^\s*[\r\n]/gm, "")}` },
                { role: "assistant", content: apires.data.response },
              ]);
            }
          }


        } else {
          setChat([
            ...chat,
            { role: "user", content: textPrompt.trim(/^\s*[\r\n]/gm, "") },
            { role: "assistant", content: apires.data.response },
          ]);
        }

        if (Inputref.current) {
          reset();
        }
      } catch (error) {
        setLoader(false);
        console.log(error);

        if (error.response.status == 429) {
          setChat([
            ...chat,
            { role: "user", content: textPrompt.trim(/^\s*[\r\n]/gm, "") },
            { role: "assistant", content: 'Ratelimit for Token exhausted. Please try again after sometime' },
          ]);
        } else {
          setChat([
            ...chat,
            { role: "user", content: textPrompt.trim(/^\s*[\r\n]/gm, "") },
            { role: "assistant", content: 'please try again' },
          ]);
        }

      }

      await getconverationdata(false);

    }
  };


  const renderoutput = (input, role) => {
    if (input === null) {
      return <p>Sorry! Please try again</p>
    }
    let parts;
    if (typeof input === "string") {
      parts = input.split("```");
    }

    return (
      <div style={{ whiteSpace: "break-spaces" }}>
        {typeof input === "string" ? (
          parts.map((item, index) =>
            index % 2 === 1 ? (
              <div key={`${item}${index}`} className="bg-black rounded-lg  ">
                <div className="flex justify-between items-center mb-4 bg-[#41414f] rounded-t-lg p-2">
                  <h3 className="text-white text-lg font-bold mr-2  ">
                    {item.substring(0, item.indexOf("\n")) ? (
                      <div>{item.substring(0, item.indexOf("\n"))}</div>
                    ) : (
                      <div>code snippet</div>
                    )}
                  </h3>
                  <button
                    onClick={() =>
                      navigator.clipboard.writeText(
                        item.substring(item.indexOf("\n"), item.length)
                      )
                    }
                  >
                    <BiCopy size={23} />
                  </button>
                </div>
                <div className="rounded-lg p-2 ">
                  <SyntaxHighlighter
                    language={
                      item.substring(0, item.indexOf("\n"))
                        ? item.substring(0, item.indexOf("\n"))
                        : "jsx"
                    }
                    style={atomDark}
                  >
                    {item.substring(item.indexOf("\n"), item.length).trim()}
                  </SyntaxHighlighter>
                </div>
              </div>
            ) : (
              <div className={role == "user" ? "fw-medium" : ""} key={`${item}${index}`}>{item}</div>
            )
          )
        ) : (
          <div>
            <p>{input.response}</p>
            <div className="d-flex flex-column gap-2">{input?.source ? (
              Array.from(Object.entries(input.source)).map((item, i) => {
                return <a href={item[1]} target="_blank">
                  {item[0]}
                </a>
              })

            ) : (
              ""
            )}</div>

          </div>
        )}
      </div>
    );
  };


  const handlecheck1 = (event, idToRemove) => {
    const item = event.target.value;
    let inputvalue;
    const isChecked = event.target.checked;
    setCheckedItems((prevItems) => {
      return isChecked ? [...prevItems, item] : prevItems.length ? prevItems.filter((i) => i !== item) : prevItems
    });
    // if (input == "") {
    //   setInput([])
    // }
    if (input.length && !isChecked) {
      const updatedItems = input?.filter((item) => item.prompt_id !== idToRemove);
      setInput(updatedItems);
    }
  };


  // Add Prompt Model function Start
  async function savePromt() {
    let prompt_title = document.getElementById("promptTitle").value;
    let prompt_input = document.getElementById("inputPrompt").value;
    if (!prompt_title || !prompt_input) {
      return alert("please provide some value");
    }

    const prompt_id = new Date().getTime();
    const userresponse = await getUserDetails();
    setLoader(true);
    try {
      await axios.put(`${url}/prompt/update`, {
        user_id: userresponse.id,
        prompt_title: prompt_title,
        prompt_desc: prompt_input,
        prompt_id: prompt_id.toString(),
      });

      setLoader(false);
      document.getElementById("promptTitle").value = "";
      document.getElementById("inputPrompt").value = "";
      await getconverationdata(false);
    } catch (error) {
      console.log(error);
    }
  }

  // radio btn for upload file

  // Admin Prompt function start

  async function adminPromptList() {
    try {
      const response = await axios.get(`${url}/adminPrompt`, {});
      setAdminPrompts(response.data);
      const result = response.data.filter((item) => {
        if (item.flag === true) { return true; }
      })
      setSelectAdminPrompt(result)
      // console.log('admin prompts', result);
    } catch (err) {
      console.log(err);
    }
  }

  function adminPromptHandle(AdminProps) {
    setClickAdminPrompt(AdminProps);
  }

  const handleCheckAdminPrompt = (event, idToRemove) => {
    const item = event.target.value;
    const promptitem = adminPrompts.find((i) => i.prompt_id == item)
    const isChecked = event.target.checked;
    setSelectAdminPrompt((prevItems) => {
      const copy = [...prevItems];
      if (isChecked) {
        return [...copy, promptitem]
      } else {
        return [...copy.filter((item) => item.prompt_id !== idToRemove)]
      }
    })

  };


  // select User Prompt function start
  function onSelectUserPrompt() {
    let selecPrompt = "";
    for (let i = 0; i < checkedItems.length; i++) {
      for (let j = 0; j < prompt_list.length; j++) {
        if (prompt_list[j].prompt_id === checkedItems[i]) {
          let user_prompts = prompt_list[j]
          selecPrompt = selecPrompt + prompt_list[j].prompt_desc + "\n";
          if (input?.length) {
            setInput((prevInput) => [...prevInput, user_prompts]);
          } else {
            setInput((prevInput) => [user_prompts]);

          }

        }
      }
    }
  }
  // select User Prompt function END



  // file PDF upload function start
  async function upload_pdfFile(event) {

    event.preventDefault();
    const userresponse = await getUserDetails();
    const formData = new FormData();
    formData.append("file", files)
    formData.append("user_id", userresponse.id);

    try {
      setPdfFile_Loader(true);
      const response = await axios.post(`${url}/upload_file`,
        formData, { cancelToken: new CancelToken(cancel => cancelFileUpload.current = cancel) }

      );
      // toast(response.data["message"]);
      setChat([...chat, { role: "user", content: `PDF File: ${files?.name}` }]);
      setCategory(`PDF File: ${files?.name}`);
      toast.success(response.data["message"], {
        position: toast.POSITION.TOP_CENTER
      });
      setselectedFolder('');
      setPdfFile_Loader(false);
      closeRef.current.click()
      reset();
      setPdfUploadInputValue('');
      // setChat([...chat, { role: "user", content: `PDF File: ${file?.name}` }]);
      // setfiles('');
    } catch (error) {
      setPdfFile_Loader(false);
      console.log(error);

      if (isCancel(error)) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER
        })
        reset();
        setPdfUploadInputValue("");
        return
      }

      if (error.response.status == 413) {
        toast.error(error.response.data["message"], {
          position: toast.POSITION.TOP_CENTER
        })
      } else if (error.response.status.toString().startsWith("4") || error.response.status.toString().startsWith("5")) {
        toast.error("PDF Upload Failed, Please try again", {
          position: toast.POSITION.TOP_CENTER
        })
      }

    }
  }

  const cancelUpload = () => {
    if (cancelFileUpload.current)
      cancelFileUpload.current("User has cancelled the file upload")
    console.log("file upload cancelled");
  }


  //pdf POPUP form reset start

  const reset = () => {
    if (Inputref.current) {
      Inputref.current.value = "";
    }

  };

  //pdf POPUP form reset END

  async function pdfFileHandle(event) {
    const file = event.target.files[0];

    setPdfUploadInputValue(file && extenstionChecker(file.name));
    // setCategory(file.name);
    setfiles(event.target.files[0]);

    if (extenstionChecker(event.target.files[0].name)) {
      console.log("PDF File")
      setFileExtensionChecker('');
    } else {
      console.log("Other file");
      setFileExtensionChecker("Please choose PDF file");
    }
  }


  async function EnterPdfFile(e) {
    let pdffileresponse;
    let isPdfExist;

    if (chat.length) {
      isPdfExist = chat.find((item) => {

        return item.role == "user" && item.content.includes(category)
      });
    }

    triggerPromptsAlert();
    reset();
    e.preventDefault();
    setselectedFolder("");
    setCategory(files.name);
    setPdfUploadInputValue('');
    let apires = "";
    let fileitem;
    setfiles((item) => {
      fileitem = item;
      return item
    })

    setLoader(true);
    if (pdf_FIle_Query.length) {
      try {
        pdffileresponse = await axios
          .post(`${url}/response`, {
            category: `PDF File: ${files.name}`,
            pdf_file_name: files.name,
            topic_id: selected,
            user_id: userDetails?.id,
            query: pdf_FIle_Query,
            prompt_ids: checkedItems,
            admin_prompt_ids: selectAdminPrompt.map((i) => i.prompt_id),
            llm_id: llmModel,
          })

        setLoader(false);
        setCheckedItems([]);
        setChat([
          ...chat,
          { role: "user", content: `PDF File: ${files.name}\n${pdf_FIle_Query}` },
          { role: "assistant", content: pdffileresponse.data.response },
        ]);
        setPdf_FIle_Query("");
        if (Inputref.current) {
          reset();
        }
        await getconverationdata(false);
      } catch (error) {
        setLoader(false);
        if (error.response.status == 429) {
          setChat([
            ...chat,
            { role: "user", content: `PDF File: ${files.name}\n${pdf_FIle_Query}` },
            { role: "assistant", content: 'Ratelimit for Token exhausted. Please try again after sometime' },
          ]);
        } else {
          setChat([
            ...chat,
            { role: "user", content: `PDF File: ${files.name}\n${pdf_FIle_Query}` },
            { role: "assistant", content: 'please try again' },
          ]);
        }
      }
    }
    setLoader(false);

  }

  // file PDF upload function end


  // extension checker function start
  function extenstionChecker(filename) {
    return filename.split('.').pop() === "pdf"
  }
  // extension checker function END


  // PDF URL function start
  async function uploadPdf_url(e) {

    triggerPromptsAlert();
    setselectedFolder("");
    setCategory(`PDF Link: ${pdfLink}`);
    let apires = "";
    e.preventDefault();

    if (extenstionChecker(pdfLink)) {
      console.log("PDF File")
    } else {
      console.log("Other file")
    }

    // setChat([...chat, { role: "user", content: `PDF Link: ${pdfLink}\n${pdf_Link_Query}` }]);
    setLoader(true);
    let categoryval;
    // setCategory((prev) => {
    //   categoryval = prev;
    //   return prev;
    // });

    try {
      const pdfuploadresponse = await axios
        .post(`${url}/upload_url`, {
          pdfLink: pdfLink,
          user_id: userDetails?.id,
        })
      setPdf_Link_Query("");
      setpdfLink("");
      setPdfUrlInputValue("");
      setCategory((prev) => {
        categoryval = prev;
        return prev;
      });
      setChat([...chat, { role: "user", content: `PDF Link: ${pdfLink}\n${pdf_Link_Query}` }]);

    } catch (error) {
      console.log(error);

      if (error.response.status == 413) {
        toast.error(error.response.data["message"], {
          position: toast.POSITION.TOP_CENTER
        })
      } else if (error.response.status.toString().startsWith("4") || error.response.status.toString().startsWith("5")) {
        toast.error("PDF Upload Failed, Please try again", {
          position: toast.POSITION.TOP_CENTER
        })
      }

    }

    if (pdf_Link_Query.length) {
      try {

        const pdfresponse = await axios
          .post(`${url}/response`, {
            category: `PDF Link: ${pdfLink}`,
            pdf_link: pdfLink,
            topic_id: selected,
            user_id: userDetails?.id,
            query: pdf_Link_Query,
            prompt_ids: checkedItems,
            admin_prompt_ids: selectAdminPrompt.map((i) => i.prompt_id),
            llm_id: llmModel, // pass the response value as the prompt parameter
          })
        setLoader(false);
        setCheckedItems([]);
        setChat([
          ...chat,
          { role: "user", content: `PDF Link: ${pdfLink}\n${pdf_Link_Query}` },
          { role: "assistant", content: pdfresponse.data.response },
        ]);
        setPdf_Link_Query("");
        setpdfLink("");
        await getconverationdata(false);
      } catch (error) {
        setLoader(false);
        console.log(error);

        if (error.response.status == 429) {
          setChat([
            ...chat,
            { role: "user", content: `PDF Link: ${pdfLink}\n${pdf_Link_Query}` },
            { role: "assistant", content: 'Ratelimit for Token exhausted. Please try again after sometime' },
          ]);
        } else {
          setChat([
            ...chat,
            { role: "user", content: `PDF Link: ${pdfLink}\n${pdf_Link_Query}` },
            { role: "assistant", content: 'please try again' },
          ]);
        }

      }
    }
    setLoader(false);

  }

  async function sharePointSelectFolder() {
    let apires;
    const userquery = textPrompt
    if (selectedFolder.length) {
      const finalcheckitems = [];
      if (input.length || checkedItems.length || selectAdminPrompt.length) {
        if (checkedItems.length && selectAdminPrompt.length) {
          finalcheckitems.push(...checkedItems, ...selectAdminPrompt);
        } else if (checkedItems.length) {
          finalcheckitems.push(...checkedItems);
        } else {
          finalcheckitems.push(...selectAdminPrompt);
        }
      }
      setChat([...chat, { role: "user", content: `Sharepoint Folder: ${selectedFolder}\n${userquery}` }]);
      setTextPrompt('');
      setInput("");
      setPdfUploadInputValue('');
      setPdf_FIle_Query('');
      setPdf_Link_Query('');
      setpdfLink('');

      setLoader(true);
      try {
        apires = await axios.post(`${url}/query_response`, {
          topic_id: selected,
          user_id: userDetails?.id,
          query: userquery,
          // prompt_ids: finalcheckitems,
          prompt_ids: checkedItems,
          admin_prompt_ids: selectAdminPrompt.map((i) => i.prompt_id),
          llm_id: llmModel,
          category: `Sharepoint Folder: ${selectedFolder}`,
        });

        setLoader(false);
        setCheckedItems([]);
        // setPopup(true);

        if (apires.data.Flag === 1) {
          await getconverationdata();
        }
        setChat([
          ...chat,
          { role: "user", content: `Sharepoint Folder: ${selectedFolder}\n${userquery}` },
          { role: "assistant", content: apires.data },
        ]);
        await getconverationdata(false);
        setCategory(selectedFolder);
        // setselectedFolder("");
      } catch (error) {
        setLoader(false);
        console.log(error);

        if (error.response.status == 429) {
          setChat([
            ...chat,
            { role: "user", content: `${selectedFolder}\n${userquery}` },
            { role: "assistant", content: 'Ratelimit for Token exhausted. Please try again after sometime' },
          ]);
        } else {
          setChat([
            ...chat,
            { role: "user", content: `${selectedFolder}\n${userquery}` },
            { role: "assistant", content: 'please try again' },
          ]);
        }

      }
    }
  }

  function triggerPromptsAlert() {
    if (checkedItems.length || selectAdminPrompt.length) {
      let userPrompts_list;
      let adminPrompts_list;
      if (checkedItems.length) {
        userPrompts_list = checkedItems.length
      }
      else {
        userPrompts_list
          = 0
      }
      if (selectAdminPrompt.length) {

        adminPrompts_list = selectAdminPrompt.length
      }
      else {
        adminPrompts_list = 0
      }

      toast.info('You have selected' + " " + adminPrompts_list + " " + 'Custom Instruction(s)' + " " + " " + 'and' + " " + userPrompts_list + " " + " " + 'User-Defined Prompt(s)', {
        position: toast.POSITION.TOP_CENTER
      });
    }
  }

  async function handleSubmit() {
    if (textPrompt.trim()) {
      if (textPrompt.length) {
        triggerPromptsAlert();
      }

      if (category != selectedFolder) {

        await handleSend();
      } else {
        await sharePointSelectFolder(selectAdminPrompt);
      }

    }

  }

  const handleKeyDown = async (e) => {
    if (e.key === "Enter" && e.shiftKey) {
      e.preventDefault();
      setTextPrompt(textPrompt + "\n");
    } else if (e.key === "Enter") {
      await handleSubmit();
      // setSelectAdminPrompt([]);
      setCheckedItems([]);
      setTextPrompt('');
    }
  };



  const getconverationdata = async (reset = true) => {
    if (reset) {
      setChat([]);
    }
    let title1;
    let title2;
    let title3;
    const userresponse = await getUserDetails();
    try {
      const response = await axios
        .get(`${url}/user/${userresponse?.id}/topic`, {})
      title1 = response.data.reverse();
      // setSelected(title1[0].topic_id)
      // setTopicList(title1.reverse());
    } catch (error) {
      console.log(error);
    }

    try {
      await axios
        .get(`${url}/user/${userresponse?.id}/prompt`, {})
        .then((response) => {
          title2 = response.data;

          // console.log("user prompts loaded", title2);
        });
    } catch (error) {
      console.log(error);
    }

    // await setTitle(title1);
    setprompt_list([]);
    setprompt_list(title2);
    await setChatHistory(title1);
    // setllm_list(title3);
  };


  // Update Topic Function Start
  async function updateName(id, newname) {
    const userresponse = await getUserDetails();
    try {
      axios.put(`${url}/topic/update_title`, {
        topic_id: selected,
        user_id: userDetails?.id,
        topic_desc: newname,
      });
    } catch (error) {
      console.log(error);
    }
    setChatHistory((prev) => {
      return prev.map((i) => {
        if (i.topic_id === id) {
          return { ...i, topic_desc: newname };
        } else {
          return i;
        }
      });
    });
  }
  // Update Topic Function End

  // Delete Topic Function Start
  async function deleteTopic(id) {
    try {
      await axios
        .put(`${url}/topic/update`, {
          topic_id: selected,
          user_id: userDetails?.id,
        })
      setSelected("");
      getconverationdata();
    } catch (error) {
      console.log(error);
    }

  }
  // Delete Topic Function End


  function handleInputChange(e) {
    setTextPrompt(e.target.value)
  }

  return <div className="container-fluid main-wrapper row g-0">

    <div className="left-sidebar col-2 position-relative">

      <div className="">
        <div>{(!selected || selected === "") && addconversation()}</div>
        <button
          className=" w-full new-chat-btn"
          onClick={addconversation} //addconversation  handleSend_demo
        >
          <span>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                {" "}
                <path
                  d="M12 7V17M7 12H17"
                  stroke="#000000"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                ></path>{" "}
              </g>
            </svg>
          </span>
          <span>New Chat</span>
        </button>{" "}

      </div>

      <div className="chat-chip-btn chat-chip-btn-wrapper mt-4">
        <ol>
          {chatHistory.map((item, index) => {
            return (
              <li
                key={`${item.topic_id}${index}`}
                onClick={() => {
                  if (item.Topic != "New Chat") {
                    selectconversation(item.topic_id);
                  }
                  setCategory("general")
                }}
              >
                {selected === item.topic_id ? (
                  <>
                    <div className="chat-chip-wrapper text-center text-lg font-light flex items-center justify-between">
                      <div className="flex flex-row">
                        <div className="m-2">
                          <BsChatLeftText />
                        </div>
                        <span className="chat-chip-label text-left">
                          {item.topic_desc.replace(/"/g, '')}
                        </span>
                      </div>
                      <div className="edit-btn">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target={`#chattopicmodal${item.topic_id}`}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="chat-chip-hover text-center  text-lg font-light flex items-center justify-between">
                      <div className="flex flex-row">
                        <div className="m-2">
                          <BsChatLeftText />
                        </div>
                        <span className="chat-chip-label text-left">
                          {item.topic_desc.replace(/"/g, '')}
                        </span>
                      </div>
                      <div className="edit-btn">
                        <button
                          data-bs-toggle="modal"
                          data-bs-target={`#chattopicmodal${item.topic_id}`}
                        >
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth="0"
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0z"></path>
                            <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04a.996.996 0 000-1.41l-2.34-2.34a.996.996 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </>
                )}
                <TopicModal
                  updateName={updateName}
                  topic={item}
                  deleteTopic={deleteTopic}
                />
              </li>
            );
          })}
        </ol>
      </div>
      {/* <div className="overflow-scroll shadow-lg hide-scroll-bar h-[20%] border-t"> */}
      <div className="logout-wrapper dropup-center dropup">
        <button className="dropdown-toggle d-flex" type="button" data-bs-toggle="dropdown" aria-expanded="false">
          <span className="me-3 mt-1">
            <svg
              viewBox="0 0 1024 1024"
              className="icon"
              width="18"
              height="18"
              version="1.1"
              xmlns="http://www.w3.org/2000/svg"
              fill="#000000"
            >
              <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
              <g
                id="SVGRepo_tracerCarrier"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></g>
              <g id="SVGRepo_iconCarrier">
                <path
                  d="M691.573 338.89c-1.282 109.275-89.055 197.047-198.33 198.331-109.292 1.282-197.065-90.984-198.325-198.331-0.809-68.918-107.758-68.998-106.948 0 1.968 167.591 137.681 303.31 305.272 305.278C660.85 646.136 796.587 503.52 798.521 338.89c0.811-68.998-106.136-68.918-106.948 0z"
                  fill="#4A5699"
                ></path>
                <path
                  d="M294.918 325.158c1.283-109.272 89.051-197.047 198.325-198.33 109.292-1.283 197.068 90.983 198.33 198.33 0.812 68.919 107.759 68.998 106.948 0C796.555 157.567 660.839 21.842 493.243 19.88c-167.604-1.963-303.341 140.65-305.272 305.278-0.811 68.998 106.139 68.919 106.947 0z"
                  fill="#C45FA0"
                ></path>
                <path
                  d="M222.324 959.994c0.65-74.688 29.145-144.534 80.868-197.979 53.219-54.995 126.117-84.134 201.904-84.794 74.199-0.646 145.202 29.791 197.979 80.867 54.995 53.219 84.13 126.119 84.79 201.905 0.603 68.932 107.549 68.99 106.947 0-1.857-213.527-176.184-387.865-389.716-389.721-213.551-1.854-387.885 178.986-389.721 389.721-0.601 68.991 106.349 68.933 106.949 0.001z"
                  fill="#E5594F"
                ></path>
              </g>
            </svg>
          </span>

          <span className="user-details" >
            {userDetails.displayName}
          </span>
        </button>
        <div className="dropdown-menu w-100">
          <div className="d-inline-flex dropdown-item" onClick={handleSignOut}>
            <span className="me-3">
              <img className="logou-icon mt-1" src="/images/logout-icon.png" alt="Log Out" style={{ height: 18 }} />
            </span>
            Log Out
          </div>
        </div>

      </div>
    </div>

    {/* <div className=" w-[60%]"> */}
    <div className="chat-wrapper col-8 position-relative ">
      {chat.length > 0 ? (
        <div className=" h-[85%] overflow-scroll hide-scroll-bar pt-8 message-room" ref={messageEl}>
          {chat.map((item, index) => (
            <div
              key={`${item.role}${index}`}
              className={` w-[95%] mx-auto p-6 text-black flex ${item.role === "assistant" && "response-chat-wrapper rounded"
                }`}
            >
              <span className=" mr-8 p-2 bg-slate-500 text-white rounded-full h-full ">
                {item.role === "user" ? (

                  <div className="user-profile-name">{userDetails.displayName[0]}</div>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-robot"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M7 7h10a2 2 0 0 1 2 2v1l1 1v3l-1 1v3a2 2 0 0 1 -2 2h-10a2 2 0 0 1 -2 -2v-3l-1 -1v-3l1 -1v-1a2 2 0 0 1 2 -2z"></path>
                    <path d="M10 16h4"></path>
                    <circle
                      cx="8.5"
                      cy="11.5"
                      r=".5"
                      fill="currentColor"
                    ></circle>
                    <circle
                      cx="15.5"
                      cy="11.5"
                      r=".5"
                      fill="currentColor"
                    ></circle>
                    <path d="M9 7l-1 -4"></path>
                    <path d="M15 7l1 -4"></path>
                  </svg>
                )}
              </span>
              <div
                className="leading-loose overflow-x-auto"
                ref={item.role == "assistant" ? ref : null}
                style={{ whiteSpace: "break-spaces" }}
              >
                {renderoutput(item.content, item.role)}
              </div>
            </div>
          ))}

        </div>
      ) : (
        <div className=" ">
          <div className="top-default-heading-wrapper">
            <div className="d-inline-block">
              <img class="logo" src="/static/media/carpenter-technology-logo.92f29e2a98df441469e7fdb05e9c4090.svg" id="carpenterLogo" />
            </div>
            <div className="font-bold main-top-heading mt-2">
              GenAI Conversational Interface
            </div>
            <div className="d-inline-block disclaimer mt-2">
            <strong>Disclaimer:</strong> Azure OpenAI is an evolving technology and may not be perfect. Its accuracy depends on the quality of the prompts provided. It should assist but not replace human judgement in critical decisions.
            </div>
          </div>
          <div className="justify-content-center d-flex d-none">
            <div className=" flex flex-wrap justify-around max-w-[900px]">
              {examples.map((item, index) => (
                <div
                  key={`${item}${index}`}
                  className=" text-lg font-light mt-4 p-4 border rounded cursor-pointer min-w-[400px] "
                  onClick={() => setInput(item)}
                >
                  {item}
                </div>
              ))}

            </div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-12">
          <div className="col-6 ps-0 character-count-wrapper">
            <div className="row">
              <div className="col-6 ">
                <div className="category-highlight ms-2 p-2">
                  Mode: {
                    category == 'general' ? 'general' : category?.startsWith("PDF Link") ? 'PDF Link' : category?.toLowerCase().endsWith('.pdf') ?<><span>PDF File</span> <span style={{fontSize: "13px"}}>*Use SharePoint mode for scanned pdf or pdf with images.</span></>: 'SharePoint'
                  }
                </div>
              </div>
            </div>
            <div className="characters-limit-count d-flex">
              {/* <span className="text-danger me-2">Max Limit: </span> */}
              {`${textPrompt.trim().length}/${llmModel == 0 ? 65040 : llmModel === 1 ? 130644 : 511500}`}

            </div>
          </div>
          <div className="col"></div>
        </div>

        <div className="col-12">
          <div className="chat-input-box-wrapper row">

            <div className="col-1 chat-input-msg-icon">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1H4.414A2 2 0 0 0 3 11.586l-2 2V2a1 1 0 0 1 1-1h12zM2 0a2 2 0 0 0-2 2v12.793a.5.5 0 0 0 .854.353l2.853-2.853A1 1 0 0 1 4.414 12H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H2z"></path>
                <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"></path>
              </svg>
            </div>

            <div className="col-9 ps-0 text-input-wrapper">
              <textarea
                maxLength={llmModel == 0 ? 65040 : llmModel === 1 ? 130644 : 511500}
                id="checkedPrompt"
                value={textPrompt}
                onChange={handleInputChange}
                onKeyDown={handleKeyDown}
                className="w-100 chat-input-box rounded-lg p-2 pr-16 hide-scroll-bar"
                placeholder="Type your message here..."
              />

            </div>
            <div className="input-icon-wrapper d-flex col-2">
              <div className="row">
                <div className="pdf-upload-success-toaster">
                  <ToastContainer />
                </div>
                <div className="col-12 d-flex justify-content-between textbox-icon-wrapper">
                  <span className="cursor-pointer" onClick={handleSubmit}>
                    {loader ? (
                      <div className="flex flex-col items-center justify-center">
                        <HashLoader color="#36d7b7" size={30} />

                      </div>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="icon icon-tabler icon-tabler-send"
                        width="25"
                        height="25"
                        viewBox="0 0 24 24"
                        strokeWidth="2"
                        stroke="currentColor"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M10 14l11 -11"></path>
                        <path d="M21 3l-6.5 18a.55 .55 0 0 1 -1 0l-3.5 -7l-7 -3.5a.55 .55 0 0 1 0 -1l18 -6.5"></path>
                      </svg>
                    )}
                  </span>

                  <span
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#fileUploadModal"
                  >

                    <img
                      className="sharepoint-icon"
                      src="/images/file-upload.png"
                      alt="file upoload"
                    />
                  </span>
                  <span
                    className="cursor-pointer"
                    data-bs-toggle="modal"
                    data-bs-target="#sharePointModal"
                  >
                    <img
                      className="sharepoint-icon"
                      src="/images/ms-sharepoint.png"
                      alt="sharepoint"
                    />
                  </span>
                </div>
              </div>

            </div>

          </div>
        </div>

      </div>
    </div>

    <div className=" right-sidebar col-2 d-lg-block d-none">
      <Tabs>
        <Tab eventKey="Prompt" title="Prompt">
          <Container>
            <Row>
              <Col className="p-0">
                <div>
                  <Accordion>
                    {" "}
                    {/*defaultActiveKey="0"*/}
                    <Accordion.Item className="mb-2" eventKey="0">
                      <Accordion.Header> Custom Instructions</Accordion.Header>
                      <Accordion.Body className="standard-body-wrapper">
                        {adminPrompts.map((item, index) => (
                          <span className="chat-chip-hover text-center  text-lg font-light flex items-center justify-between" key={`${item.prompt_id}${index}`}>
                            <span className="flex flex-row">
                              <span className=" mr-4">
                                <input
                                  type="checkbox"
                                  id={item.prompt_id}
                                  value={item.prompt_id}
                                  onChange={(event) => handleCheckAdminPrompt(event, item.prompt_id)}
                                  className="transform scale-150"
                                  defaultChecked={selectAdminPrompt.map((i) => i.prompt_id).includes(item.prompt_id)}
                                ></input>
                              </span>
                              <label
                                htmlFor={item.prompt_id}
                                className="chat-chip-label text-left"
                              >
                                {item.prompt_title}
                              </label>
                            </span>
                            <span tabIndex="0" data-bs-toggle="tooltip" data-bs-title="Disabled tooltip" className="d-inline-block">
                              <img
                                onClick={adminPromptHandle.bind(this, item)}
                                data-bs-toggle="modal"
                                data-bs-target="#adminPromptModal"
                                className="admin-prompt-eye"
                                src="/images/more-info-icon.svg"
                                alt="file upoload"
                              />
                            </span>

                          </span>
                        ))}


                        <div
                          data-bs-backdrop="static"
                          data-bs-keyboard="false"
                          className="modal fade"
                          id="adminPromptModal"
                          tabIndex="-1"
                          aria-labelledby="adminPromptModalLabel"
                          aria-hidden="true"
                        >
                          <div className="modal-dialog position-relative">

                            <div className="modal-content px-4 py-2 rounded-0">
                              <div className="position-absolute close-btn">
                                <button
                                  type="button"
                                  className="btn-close"
                                  data-bs-dismiss="modal"
                                  aria-label="Close"
                                ></button>
                              </div>
                              <div className="modal-header d-block border-0 pb-0 text-left">
                                <h5
                                  className="modal-title"
                                  id="adminPromptModalLabel"
                                >
                                  Custom Instructions
                                </h5>

                              </div>
                              <div className="modal-body">
                                <div className="form-floating mb-3">
                                  <input
                                    type="text"
                                    value={clickAdminPrompt.prompt_title}
                                    className="form-control"
                                    aria-label="Disabled input example"
                                    disabled
                                    id="adminPromptTitle"
                                    placeholder=" "
                                  />
                                  <label htmlFor="adminPromptTitle">
                                    Prompt Title
                                  </label>
                                </div>
                                <div className="form-floating mt-4">
                                  <textarea
                                    value={clickAdminPrompt.prompt_desc}
                                    className="form-control"
                                    aria-label="Disabled input example"
                                    disabled
                                    placeholder=" "
                                    id="adminPrompt"
                                  ></textarea>
                                  <label htmlFor="adminPrompt">
                                    Your Prompt
                                  </label>
                                </div>
                              </div>
                              <div className="modal-footer border-0">
                                <button
                                  type="button"
                                  className="btn border-0 pe-0"
                                  data-bs-dismiss="modal"
                                >
                                  Cancel
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item className="mb-2" eventKey="1">
                      <Accordion.Header>User-Defined Prompt</Accordion.Header>
                      <Accordion.Body className="userdefined-body-wrapper">
                        <div>
                          <div className="chat-chip-btn mb-3 ">
                            <span className="">
                              <button
                                data-bs-toggle="modal"
                                data-bs-target="#promptModal"
                                className="w-full new-chat-btn"
                                id="New_Prompt"

                              >
                                <span>
                                  <svg
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <g
                                      id="SVGRepo_bgCarrier"
                                      strokeWidth="0"
                                    ></g>
                                    <g
                                      id="SVGRepo_tracerCarrier"
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                    ></g>
                                    <g id="SVGRepo_iconCarrier">
                                      {" "}
                                      <path
                                        d="M12 7V17M7 12H17"
                                        stroke="#000000"
                                        strokeWidth="1.5"
                                        strokeLinecap="round"
                                        strokeLinejoin="round"
                                      ></path>{" "}
                                    </g>
                                  </svg>
                                </span>
                                <span>New Prompt</span>
                              </button>
                            </span>
                          </div>

                          <div className="chat-chip-btn-wrapper">
                            {prompt_list.map((item, index) => (
                              <span
                                className="chat-list-item"
                                key={item.prompt_id}
                              >

                                <span className="chat-chip-hover text-center  text-lg font-light flex items-center justify-between">
                                  <span className="flex flex-row">
                                    <span className=" mr-4">
                                      <input
                                        id={item.prompt_id}
                                        type="checkbox"
                                        value={item.prompt_id}
                                        className="transform scale-150"
                                        onChange={(event) => handlecheck1(event, item.prompt_id)}
                                        checked={checkedItems.includes(
                                          item.prompt_id
                                        )}
                                      ></input>
                                    </span>
                                    <label
                                      htmlFor={item.prompt_id}
                                      className="chat-chip-label text-left"
                                    >
                                      {item.prompt_title}{" "}
                                    </label>
                                  </span>
                                  <span className="edit-prompt-modal">
                                    <PromptModel
                                      setTextPrompt={setTextPrompt}
                                      setLoader={setLoader}
                                      getconverationdata={getconverationdata}
                                      prompt={item}
                                    />
                                  </span>
                                </span>
                              </span>
                            ))}
                          </div>
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                  {/* <div>Test</div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </Tab>
        <Tab
          eventKey="Configuration"
          title="Configuration"
          className="configure-wrapper mx-2"
        >
          <div className="form-floating">
            <select
              className="form-select"
              id="floatingSelect"
              onChange={(e) => setllmModel(parseInt(e.target.value))}
              aria-label="Floating label select example"
            >
              <option defaultValue={llmModel} value="1">GPT 4</option>
              <option value="0">GPT 3.5 Turbo</option>

              <option value="2">GPT 4 Turbo</option>
            </select>
            <label htmlFor="floatingSelect">Choose Model</label>
          </div>
        </Tab>
      </Tabs>



      <div
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        className="modal fade"
        id="promptModal"
        tabIndex="-1"
        aria-labelledby="promptModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog position-relative">

          <div className="modal-content px-4 py-2 rounded-0">
            <div className="position-absolute close-btn">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-block border-0 pb-0">
              <h5 className="modal-title" id="promptModalLabel">
                Prompt
              </h5>
              {/* <p>Please write your prompt title and prompt for your choice.</p> */}
            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">
                <input
                  type="text"
                  className="form-control"
                  id="promptTitle"
                  placeholder="Prompt title"
                />
                <label htmlFor="promptTitle">Prompt Title</label>
              </div>
              <div className="form-floating mt-4">
                <textarea
                  className="form-control"
                  placeholder="Write Your Prompt"
                  id="inputPrompt"
                ></textarea>
                <label htmlFor="inputPrompt">Write Your Prompt</label>
              </div>
            </div>
            <div className="modal-footer border-0">
              <button
                data-bs-dismiss="modal"
                onClick={savePromt}
                id="savePrompt"
                type="button"
                className="btn btn-outline-primary px-5"
              >
                Save
              </button>
              <button
                type="button"
                className="btn border-0 pe-0"
                onClick={() => {
                  document.getElementById("promptTitle").value = "";
                  document.getElementById("inputPrompt").value = "";
                }}
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>


      <div
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        className="modal fade"
        id="fileUploadModal"
        tabIndex="-1"
        aria-labelledby="fileUploadModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog position-relative">

          <div className="modal-content px-4 py-2 rounded-0">
            <div className="position-absolute close-btn">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-block border-0 pb-0 ps-0">
              <h5 className="modal-title" id="fileUploadModalLabel">
                File Upload
              </h5>
            </div>

            <div className="d-flex mb-3 mt-2">
              <div className="form-check">
                <input
                  onChange={(e) => {
                    if (e.target.checked) set_fileUpload("PDFFILE");
                  }}
                  className="form-check-input pdf-upload"
                  type="radio"
                  name="action"
                  id="flexRadioDefault1"
                  checked={file_upload_option === "PDFFILE"}

                />
                <label
                  className="form-check-label"
                  htmlFor="flexRadioDefault1"
                >
                  PDF
                </label>
              </div>
              <div className="form-check ms-3">
                <input
                  onChange={(e) => {
                    if (e.target.checked) set_fileUpload("PDFLINK");
                  }}
                  className="form-check-input pdf-link"
                  type="radio"
                  name="action"
                  id="flexRadioDefault2"
                  checked={file_upload_option === "PDFLINK"}
                />
                <label
                  className="form-check-label"
                  htmlFor="flexRadioDefault2"
                >
                  PDF URL
                </label>
              </div>
            </div>

            {file_upload_option === "PDFFILE" ? (
              <form onSubmit={(e) => upload_pdfFile(e)}>
                <div id="pdfUpload" className="">
                  <div className="modal-body file-upload-wrapper ps-0 pe-0 ">

                    <div className="form-floating mb-3">
                      <input
                        ref={Inputref}
                        onChange={(event) => pdfFileHandle(event)}
                        type="file"
                        className="form-control position-relative"
                        id="pdfFile"
                        placeholder="Prompt title"
                      />
                      <label htmlFor="pdfFile">File Name</label>
                      <img
                        className="sharepoint-icon"
                        src="/images/file-upload.png"
                        alt="file upoload"
                      />
                    </div>
                    <p className="text-danger pdf-extension-alert">
                      {fileExtensionChecker}
                    </p>
                    {pdfFile_Loader &&
                      <div className="text-center pdf-file-loader">
                        <div className="spinner-border text-info" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                      // : <div className="form-floating mt-4">
                      //   <textarea
                      //     className="form-control pdf-file-prompt"
                      //     onChange={(event) => setPdf_FIle_Query(event.target.value)}
                      //     value={pdf_FIle_Query}
                      //     placeholder=" "
                      //     id="pdfPrompt"
                      //     rows="3"

                      //   ></textarea>
                      //   <label htmlFor="pdfPrompt">
                      //     What do you want to do with this file?
                      //   </label>
                      // </div>
                    }

                  </div>
                  <div className="modal-footer border-0 pe-0">
                    <button
                      disabled={!PdfUploadInputValue || pdfFile_Loader}
                      type="submit"
                      className="btn btn-outline-primary px-5"
                    >
                      Upload
                    </button>

                    {pdfFile_Loader ?
                      <>  <button
                        ref={closeRef}
                        type="button"
                        className="btn border-0 pe-0"
                        data-bs-dismiss="modal"
                        onClick={() => cancelUpload()}
                      >
                        Cancel
                      </button></>
                      : <></>
                    }


                  </div>
                </div>
              </form>
            ) : (
              <form onSubmit={(e) => uploadPdf_url(e, selectAdminPrompt.map((item) => item.prompt_id))}>
                <div id="pdfLink" className="">
                  <div className="modal-body file-upload-wrapper pe-0 ps-0">
                    <div className="form-floating mb-3">
                      <input
                        onChange={(event) => {
                          setPdfUrlInputValue(extenstionChecker(event.target.value));
                          setpdfLink(event.target.value);
                          if (!extenstionChecker(event.target.value)) {
                            setFileExtensionChecker('Choose PDF Link');
                          } else {
                            setFileExtensionChecker('');
                          }
                        }}
                        type="text"
                        value={pdfLink}
                        className="form-control position-relative"
                        id="floatingInput"
                        placeholder="PDF Link"
                      />
                      <label htmlFor="floatingInput">PDF URL Link</label>
                      {/* <img
                        className="sharepoint-icon"
                        src="/images/link-share.png"
                        alt="file upoload"
                      /> */}
                    </div>
                    <p className="text-danger pdf-extension-alert">
                      {fileExtensionChecker}
                    </p>
                    {/* <div className="form-floating mt-4">
                      <textarea
                        onChange={(event) => setPdf_Link_Query(event.target.value)}
                        value={pdf_Link_Query}
                        className="form-control"
                        placeholder=" "
                        id="pdfLink"
                      ></textarea>
                      <label htmlFor="pdfLink">
                        What do you want to do with this file?
                      </label>
                    </div> */}
                  </div>
                  <div className="modal-footer border-0 pe-0">
                    <button
                      disabled={!pdfUrlInputValue}
                      data-bs-dismiss="modal"
                      type="submit"
                      className="btn btn-outline-primary px-5"
                    >
                      Upload
                    </button>
                    <button
                      type="button"
                      className="btn border-0 pe-0"
                      data-bs-dismiss="modal"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </form>
            )}
          </div>
        </div>
      </div>

      <SharePointModal
        setselectedFolder={setselectedFolder}
        selectedFolder={selectedFolder}

        setCategory={setCategory}
      />
      <div className="prompts-count-toaster">
        <ToastContainer />
      </div>
    </div>

    {/* right-sidebar in tablet start */}

    <nav class="navbar fixed-top d-lg-none d-block"> {/* d-lg-none d-block */}
      <div class="container-fluid d-block text-end">
        <button class="navbar-toggler" type="button" onClick={handleShow}>
          <span class="navbar-toggler-icon"></span>
        </button>
        <Offcanvas show={show} onHide={handleClose} backdrop="static" placement="end">
          {/* <Offcanvas.Header closeButton>
            <Offcanvas.Title></Offcanvas.Title>
          </Offcanvas.Header> */}
          <Offcanvas.Body>

            <div className="right-sidebar">
              <Offcanvas.Header className="pb-0 d-block text-end" closeButton>
              </Offcanvas.Header>
              <Tabs>
                <Tab eventKey="Prompt" title="Prompt">
                  <Container>
                    <Row>
                      <Col className="p-0">
                        <div>
                          <Accordion>
                            {" "}
                            {/*defaultActiveKey="0"*/}
                            <Accordion.Item className="mb-2" eventKey="0">
                              <Accordion.Header> Custom Instructions</Accordion.Header>
                              <Accordion.Body className="standard-body-wrapper">
                                {adminPrompts.map((item, index) => (
                                  <span className="chat-chip-hover text-center  text-lg font-light flex items-center justify-between" key={`${item.prompt_id}${index}`}>
                                    <span className="flex flex-row">
                                      <span className=" mr-4">
                                        <input
                                          type="checkbox"
                                          id={item.prompt_id}
                                          value={item.prompt_id}
                                          onChange={(event) => handleCheckAdminPrompt(event, item.prompt_id)}
                                          className="transform scale-150"
                                          defaultChecked={selectAdminPrompt.map((i) => i.prompt_id).includes(item.prompt_id)}
                                        ></input>
                                      </span>
                                      <label
                                        htmlFor={item.prompt_id}
                                        className="chat-chip-label text-left"
                                      >
                                        {item.prompt_title}
                                      </label>
                                    </span>
                                    <span tabIndex="0" data-bs-toggle="tooltip" data-bs-title="Disabled tooltip" className="d-inline-block">
                                      <img
                                        onClick={adminPromptHandle.bind(this, item)}
                                        data-bs-toggle="modal"
                                        data-bs-target="#adminPromptModal"
                                        className="admin-prompt-eye"
                                        src="/images/more-info-icon.svg"
                                        alt="file upoload"
                                      />
                                    </span>

                                  </span>
                                ))}


                                <div
                                  data-bs-backdrop="static"
                                  data-bs-keyboard="false"
                                  className="modal fade"
                                  id="adminPromptModal"
                                  tabIndex="-1"
                                  aria-labelledby="adminPromptModalLabel"
                                  aria-hidden="true"
                                >
                                  <div className="modal-dialog position-relative">

                                    <div className="modal-content px-4 py-2 rounded-0">
                                      <div className="position-absolute close-btn">
                                        <button
                                          type="button"
                                          className="btn-close"
                                          data-bs-dismiss="modal"
                                          aria-label="Close"
                                        ></button>
                                      </div>
                                      <div className="modal-header d-block border-0 pb-0 text-left">
                                        <h5
                                          className="modal-title"
                                          id="adminPromptModalLabel"
                                        >
                                          Custom Instructions
                                        </h5>

                                      </div>
                                      <div className="modal-body">
                                        <div className="form-floating mb-3">
                                          <input
                                            type="text"
                                            value={clickAdminPrompt.prompt_title}
                                            className="form-control"
                                            aria-label="Disabled input example"
                                            disabled
                                            id="adminPromptTitle"
                                            placeholder=" "
                                          />
                                          <label htmlFor="adminPromptTitle">
                                            Prompt Title
                                          </label>
                                        </div>
                                        <div className="form-floating mt-4">
                                          <textarea
                                            value={clickAdminPrompt.prompt_desc}
                                            className="form-control"
                                            aria-label="Disabled input example"
                                            disabled
                                            placeholder=" "
                                            id="adminPrompt"
                                          ></textarea>
                                          <label htmlFor="adminPrompt">
                                            Your Prompt
                                          </label>
                                        </div>
                                      </div>
                                      <div className="modal-footer border-0">
                                        <button
                                          type="button"
                                          className="btn border-0 pe-0"
                                          data-bs-dismiss="modal"
                                        >
                                          Cancel
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>

                              </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item className="mb-2" eventKey="1">
                              <Accordion.Header>User-Defined Prompt</Accordion.Header>
                              <Accordion.Body className="userdefined-body-wrapper">
                                <div>
                                  <div className="chat-chip-btn mb-3 ">
                                    <span className="">
                                      <button
                                        data-bs-toggle="modal"
                                        data-bs-target="#promptModal"
                                        className="w-full new-chat-btn"
                                        id="New_Prompt"

                                      >
                                        <span>
                                          <svg
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <g
                                              id="SVGRepo_bgCarrier"
                                              strokeWidth="0"
                                            ></g>
                                            <g
                                              id="SVGRepo_tracerCarrier"
                                              strokeLinecap="round"
                                              strokeLinejoin="round"
                                            ></g>
                                            <g id="SVGRepo_iconCarrier">
                                              {" "}
                                              <path
                                                d="M12 7V17M7 12H17"
                                                stroke="#000000"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              ></path>{" "}
                                            </g>
                                          </svg>
                                        </span>
                                        <span>New Prompt</span>
                                      </button>
                                    </span>
                                  </div>

                                  <div className="chat-chip-btn-wrapper">
                                    {prompt_list.map((item, index) => (
                                      <span
                                        className="chat-list-item"
                                        key={item.prompt_id}
                                      >

                                        <span className="chat-chip-hover text-center  text-lg font-light flex items-center justify-between">
                                          <span className="flex flex-row">
                                            <span className=" mr-4">
                                              <input
                                                id={item.prompt_id}
                                                type="checkbox"
                                                value={item.prompt_id}
                                                className="transform scale-150"
                                                onChange={(event) => handlecheck1(event, item.prompt_id)}
                                                checked={checkedItems.includes(
                                                  item.prompt_id
                                                )}
                                              ></input>
                                            </span>
                                            <label
                                              htmlFor={item.prompt_id}
                                              className="chat-chip-label text-left"
                                            >
                                              {item.prompt_title}{" "}
                                            </label>
                                          </span>
                                          <span className="edit-prompt-modal">
                                            <PromptModel
                                              setTextPrompt={setTextPrompt}
                                              setLoader={setLoader}
                                              getconverationdata={getconverationdata}
                                              prompt={item}
                                            />
                                          </span>
                                        </span>
                                      </span>
                                    ))}
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                          {/* <div>Test</div> */}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Tab>
                <Tab
                  eventKey="Configuration"
                  title="Configuration"
                  className="configure-wrapper mx-2"
                >
                  <div className="form-floating">
                    <select
                      className="form-select"
                      id="floatingSelect"
                      onChange={(e) => setllmModel(parseInt(e.target.value))}
                      aria-label="Floating label select example"
                    >

                      <option value="0">GPT 3.5 Turbo</option>

                      <option value="2">GPT 4 Turbo</option>
                    </select>
                    <label htmlFor="floatingSelect">Choose Model</label>
                  </div>
                </Tab>
              </Tabs>



              <div
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                className="modal fade"
                id="promptModal"
                tabIndex="-1"
                aria-labelledby="promptModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog position-relative">

                  <div className="modal-content px-4 py-2 rounded-0">
                    <div className="position-absolute close-btn">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-header d-block border-0 pb-0">
                      <h5 className="modal-title" id="promptModalLabel">
                        Prompt
                      </h5>
                      {/* <p>Please write your prompt title and prompt for your choice.</p> */}
                    </div>
                    <div className="modal-body">
                      <div className="form-floating mb-3">
                        <input
                          type="text"
                          className="form-control"
                          id="promptTitle"
                          placeholder="Prompt title"
                        />
                        <label htmlFor="promptTitle">Prompt Title</label>
                      </div>
                      <div className="form-floating mt-4">
                        <textarea
                          className="form-control"
                          placeholder="Write Your Prompt"
                          id="inputPrompt"
                        ></textarea>
                        <label htmlFor="inputPrompt">Write Your Prompt</label>
                      </div>
                    </div>
                    <div className="modal-footer border-0">
                      <button
                        data-bs-dismiss="modal"
                        onClick={savePromt}
                        id="savePrompt"
                        type="button"
                        className="btn btn-outline-primary px-5"
                      >
                        Save
                      </button>
                      <button
                        type="button"
                        className="btn border-0 pe-0"
                        onClick={() => {
                          document.getElementById("promptTitle").value = "";
                          document.getElementById("inputPrompt").value = "";
                        }}
                        data-bs-dismiss="modal"
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>


              <div
                data-bs-backdrop="static"
                data-bs-keyboard="false"
                className="modal fade"
                id="fileUploadModal"
                tabIndex="-1"
                aria-labelledby="fileUploadModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog position-relative">

                  <div className="modal-content px-4 py-2 rounded-0">
                    <div className="position-absolute close-btn">
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-label="Close"
                      ></button>
                    </div>
                    <div className="modal-header d-block border-0 pb-0 ps-0">
                      <h5 className="modal-title" id="fileUploadModalLabel">
                        File Upload
                      </h5>
                    </div>

                    <div className="d-flex mb-3 mt-2">
                      <div className="form-check">
                        <input
                          onChange={(e) => {
                            if (e.target.checked) set_fileUpload("PDFFILE");
                          }}
                          className="form-check-input pdf-upload"
                          type="radio"
                          name="action"
                          id="flexRadioDefault1"
                          checked={file_upload_option === "PDFFILE"}

                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault1"
                        >
                          PDF
                        </label>
                      </div>
                      <div className="form-check ms-3">
                        <input
                          onChange={(e) => {
                            if (e.target.checked) set_fileUpload("PDFLINK");
                          }}
                          className="form-check-input pdf-link"
                          type="radio"
                          name="action"
                          id="flexRadioDefault2"
                          checked={file_upload_option === "PDFLINK"}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="flexRadioDefault2"
                        >
                          PDF URL
                        </label>
                      </div>
                    </div>

                    {file_upload_option === "PDFFILE" ? (
                      <form onSubmit={(e) => upload_pdfFile(e)}>
                        <div id="pdfUpload" className="">
                          <div className="modal-body file-upload-wrapper ps-0 pe-0 ">

                            <div className="form-floating mb-3">
                              <input
                                ref={Inputref}
                                onChange={(event) => pdfFileHandle(event)}
                                type="file"
                                className="form-control position-relative"
                                id="pdfFile"
                                placeholder="Prompt title"
                              />
                              <label htmlFor="pdfFile">File Name</label>
                              <img
                                className="sharepoint-icon"
                                src="/images/file-upload.png"
                                alt="file upoload"
                              />
                            </div>
                            <p className="text-danger pdf-extension-alert">
                              {fileExtensionChecker}
                            </p>
                            {pdfFile_Loader &&
                              <div className="text-center pdf-file-loader">
                                <div className="spinner-border text-info" role="status">
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                              </div>
                              // : <div className="form-floating mt-4">
                              //   <textarea
                              //     className="form-control pdf-file-prompt"
                              //     onChange={(event) => setPdf_FIle_Query(event.target.value)}
                              //     value={pdf_FIle_Query}
                              //     placeholder=" "
                              //     id="pdfPrompt"
                              //     rows="3"

                              //   ></textarea>
                              //   <label htmlFor="pdfPrompt">
                              //     What do you want to do with this file?
                              //   </label>
                              // </div>
                            }

                          </div>
                          <div className="modal-footer border-0 pe-0">
                            <button
                              disabled={!PdfUploadInputValue || pdfFile_Loader}
                              type="submit"
                              className="btn btn-outline-primary px-5"
                            >
                              Upload
                            </button>

                            {pdfFile_Loader ?
                              <>  <button
                                ref={closeRef}
                                type="button"
                                className="btn border-0 pe-0"
                                data-bs-dismiss="modal"
                                onClick={() => cancelUpload()}
                              >
                                Cancel
                              </button></>
                              : <></>
                            }

                          </div>
                        </div>
                      </form>
                    ) : (
                      <form onSubmit={(e) => uploadPdf_url(e, selectAdminPrompt.map((item) => item.prompt_id))}>
                        <div id="pdfLink" className="">
                          <div className="modal-body file-upload-wrapper pe-0 ps-0">
                            <div className="form-floating mb-3">
                              <input
                                onChange={(event) => {
                                  setPdfUrlInputValue(extenstionChecker(event.target.value));
                                  setpdfLink(event.target.value);
                                  if (!extenstionChecker(event.target.value)) {
                                    setFileExtensionChecker('Choose PDF Link');
                                  } else {
                                    setFileExtensionChecker('');
                                  }
                                }}
                                type="text"
                                value={pdfLink}
                                className="form-control position-relative"
                                id="floatingInput"
                                placeholder="PDF Link"
                              />
                              <label htmlFor="floatingInput">PDF URL Link</label>
                              {/* <img
                        className="sharepoint-icon"
                        src="/images/link-share.png"
                        alt="file upoload"
                      /> */}
                            </div>
                            <p className="text-danger pdf-extension-alert">
                              {fileExtensionChecker}
                            </p>
                            {/* <div className="form-floating mt-4">
                      <textarea
                        onChange={(event) => setPdf_Link_Query(event.target.value)}
                        value={pdf_Link_Query}
                        className="form-control"
                        placeholder=" "
                        id="pdfLink"
                      ></textarea>
                      <label htmlFor="pdfLink">
                        What do you want to do with this file?
                      </label>
                    </div> */}
                          </div>
                          <div className="modal-footer border-0 pe-0">
                            <button
                              disabled={!pdfUrlInputValue}
                              data-bs-dismiss="modal"
                              type="submit"
                              className="btn btn-outline-primary px-5"
                            >
                              Upload
                            </button>
                            <button
                              type="button"
                              className="btn border-0 pe-0"
                              data-bs-dismiss="modal"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>

              <SharePointModal
                setselectedFolder={setselectedFolder}
                selectedFolder={selectedFolder}

                setCategory={setCategory}
              />
              <div className="prompts-count-toaster">
                <ToastContainer />
              </div>
            </div>
          </Offcanvas.Body>
        </Offcanvas>
      </div>
    </nav>


    {/* right-sidebar in tablet End */}


  </div>

};

export default Chat;

const PromptModel = (props) => {
  // console.log("props", props);
  const { prompt, setLoader, getconverationdata, setTextPrompt } = props;

  const [promptData, setPromptData] = useState(prompt);
  function handleChange(value) {

    setPromptData({

      prompt_title: value,
    });

  }

  function handleChange(e) {
    setPromptData((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  }

  async function updatePrompt() {

    const userresponse = await getUserDetails();
    setLoader(true);
    try {
      axios.put(`${url}/prompt/update`, {
        user_id: userresponse.id,
        prompt_title: promptData.prompt_title,
        prompt_desc: promptData.prompt_desc,
        prompt_id: props.prompt.prompt_id.toString(),
      });
      setLoader(false);
      await getconverationdata(false);
    } catch (error) {
      console.log(error);
    }
  }


  // Delete userPrompt function start

  async function deleteUserPrompt() {
    const userresponse = await getUserDetails();
    setLoader(true);
    try {
      axios.post(`${url}/prompt/delete`, {
        user_id: userresponse.id,
        prompt_id: props.prompt.prompt_id.toString(),
      });
      setLoader(false);
      setTextPrompt('');
      getconverationdata(false);
    } catch (error) {
      console.log(error);
    }

  }

  // Delete userPrompt function end

  // Update Prompt Model function End
  return (
    <div>
      <span>
        {prompt.predefined ?

          <span>
            <img
              data-bs-toggle="modal"
              data-bs-target={`#promptEditModal${prompt.prompt_id}`}
              className="admin-prompt-eye"
              src="/images/more-info-icon.svg"
              alt="Info predefined prompt"
            />
          </span> :

          <button
            data-bs-toggle="modal"
            data-bs-target={`#promptEditModal${prompt.prompt_id}`}
          >
            <MdEdit />
          </button>
        }
      </span>
      <div
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        className="modal fade"
        id={`promptEditModal${prompt.prompt_id}`}
        tabIndex="-1"
        aria-labelledby="promptEditModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog position-relative">

          <div className="modal-content px-4 py-2 rounded-0">
            <div className="position-absolute close-btn">
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-header d-block border-0 pb-0 text-left">
              <h5 className="modal-title" id="promptEditModalLabel">
                Prompt
              </h5>

            </div>
            <div className="modal-body">
              <div className="form-floating mb-3">

                {prompt.predefined ?
                  <input
                    type="text"
                    className="form-control"
                    value={promptData.prompt_title}
                    onChange={(e) => handleChange(e)}
                    name="prompt_title"
                    id="Title"
                    placeholder="Prompt title"
                    aria-label="Disabled input" disabled
                  />
                  :
                  <input
                    type="text"
                    className="form-control"
                    value={promptData.prompt_title}
                    onChange={(e) => handleChange(e)}
                    name="prompt_title"
                    id="Title"
                    placeholder="Prompt title"
                  />
                }

                <label htmlFor="Title">Prompt Title</label>
              </div>

              <div className="form-floating mt-4">

                {prompt.predefined ?

                  <textarea
                    className="form-control"
                    value={promptData.prompt_desc}
                    onChange={(e) => handleChange(e)}
                    name={"prompt_desc"}
                    placeholder="Write Your Prompt"
                    id="inputprompt"
                    aria-label="Disabled input" disabled
                  ></textarea>

                  :

                  <textarea
                    className="form-control"
                    value={promptData.prompt_desc}
                    onChange={(e) => handleChange(e)}
                    name={"prompt_desc"}
                    placeholder="Write Your Prompt"
                    id="inputprompt"
                  ></textarea>
                }
                <label htmlFor="inputprompt">Write Your Prompt</label>

              </div>
            </div>
            <div className="modal-footer border-0">
              {prompt.predefined ? <></> : <>
                <button
                  type="button"
                  onClick={() => updatePrompt()}
                  data-bs-dismiss="modal"
                  className="btn btn-outline-primary px-5"
                >
                  Update
                </button>
                <button
                  onClick={deleteUserPrompt}
                  data-bs-dismiss="modal"
                  type="button"
                  className="btn border-0 pe-0 text-danger"
                >
                  Delete
                </button>
              </>
              }

              <button
                type="button"
                onClick={() => setPromptData(prompt)}
                className="btn border-0 pe-0"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
