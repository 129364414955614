import React, { useState } from "react";
import { useEffect } from "react";
import FolderItem from "./FolderItem";
import { getSharePointFolders } from "./Sharepoint";

export default function SharePointModal({ setselectedFolder, selectedFolder, setCategory }) {
  const [folders, setfolders] = useState([]);
  const [loading, setLoading] = useState(false);

  const changeHandler = (e) => {
    setselectedFolder(e.target.value);
    // setCategory(e.target.value)
  };
  useEffect(() => {
    setLoading(true);
    getSharePointFolders().then((res) => {
      // console.log("response",res);
      setLoading(false);
      setfolders(res);
    });
  }, []);
  const submitHandler = () => {
    if (selectedFolder) {
      setCategory(selectedFolder)
      // console.log("Folder", selectedFolder);
    }
  };

  if (loading) {
    return  <div
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
      className="modal fade"
      id="sharePointModal"
      tabIndex="-1"
      aria-labelledby="sharePointModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg position-relative">

        <div className="modal-content px-4 py-2 rounded-0 sharepoint-popup-wrapper">
        <div className="position-absolute close-btn">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
          <div className="modal-header d-block border-0 pb-0 ps-0">
            <h5 className="modal-title" id="sharePointModalLabel">
              Available Folders
            </h5>
            {/* <p>Please Select Any One Folder</p> */}
          </div>
          <div className="modal-body file-upload-wrapper text-center">
            <div className="spinner-border text-info" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
          <div className="modal-footer border-0">
            <button type="button" className="btn btn-outline-primary px-5" data-bs-dismiss="modal" >
              Ok
            </button>
            <button
              type="button"
              className="btn border-0 pe-0"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>

  }

  return (
    <div
      data-bs-backdrop="static" 
      data-bs-keyboard="false"
      className="modal fade"
      id="sharePointModal"
      tabIndex="-1"
      aria-labelledby="sharePointModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg position-relative">
       
        <div className="modal-content px-4 py-2 rounded-0 sharepoint-popup-wrapper">
        <div className="position-absolute close-btn">
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
          <div className="modal-header d-block border-0 pb-0 ps-0">
            <h5 className="modal-title" id="sharePointModalLabel">
              Available Folders
            </h5>
            {/* <p>Please Select Any One Folder</p> */}
          </div>
          <div className="modal-body file-upload-wrapper ">
            <div className="row folder-section"> {/*  <div className={folders?.length > 10 ? "row folder-section" : ""}>*/}
              {folders?.length &&
                folders.sort((a, b) => a.name.localeCompare(b.name)).map((item) => (
                  <FolderItem
                    key={item.id}
                    id={item.id}
                    weburl={item.webUrl}
                    name={item.name}
                    selectedfolder={selectedFolder}
                    changeHandler={changeHandler}
                  />
                ))}
            </div>
          </div>
          <div className="modal-footer border-0">
            <button type="button" onClick={() => submitHandler()} className="btn btn-outline-primary px-5" data-bs-dismiss="modal" >
              Ok
            </button>
            <button
              type="button"
              className="btn border-0 pe-0"
              data-bs-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
